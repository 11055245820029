import styled, {css} from 'styled-components'
import {device} from '../../theme'
import BackgroundImage from 'gatsby-background-image'


export const CityContainer = styled.div`
    .top-ten-item{
       
        .site-exists{ 
            border-bottom:1px dotted #000;
        }
        span{
            margin-right:5px;

        }
    }
`

export const Weather = styled.div`
    width: 100%;
    border-radius:8px;
    position:relative;
    padding:5px;
    padding-left:45px; 
    padding-top:5px;  
    .weather-icon{
        opacity:.8;
        display:block;
        position:absolute;
        top:0;
        left:-10px;
        height:100%;
        img{
            height:80%;
            margin-top:5px;
        }
    }
    p{
        font-size:1.25rem;
        color:#2a1b1b;
        font-family:'CerebriSans';
        font-weight:700;
        margin-bottom:0;
     .degree{
        margin-right: 4px;        
        &:after {
          content: "o";
          font-size:14px;
          position:absolute;
          top:5px;
        } 
     }
     .weather-description{
                font-weight:500;
                margin-left:10px;

     }
    } 
    .unit{
         font-size:1.4rem;
         position:absolute;
         right:0;
         color:#fff;
     }     
`;

export const BannerArea = styled.div`
    padding-top: 85px;
    padding-bottom: 195px;    
    background-position: center center;
    position:relative;
    background:rgb(36, 41, 67);
    &:before{
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        background: url(${props => props.image});
        background-size:cover;
        background-position: center center;
        top:0;
        opacity:.2;
    }
    
    @media ${device.large}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media ${device.medium}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;

export const BannerTextWrap = styled.div `
    text-align: center;
`;

export const OverviewArea = styled.section `
    padding-top: 100px;
`;

export const ClientBox = styled.div `
    margin-bottom: 40px;
`;

export const MetaBox = styled.div `
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    padding-top: 16px;
    padding-bottom: 16px;
    ${props => props.boxtype === 'share-box' && css `
        border-bottom: none;
        margin-top: 0;
    `}
`;

export const ProblemArea = styled.section `
    padding-top: 60px;
`;

export const ProblemBox = styled.div `
    border-bottom: 1px solid #eee;
    padding-bottom: 50px;
    margin-bottom: 44px;
`;

export const ProblemTextBox = styled.div `
    display: flex;
`;

export const ResultArea = styled.section `
    padding-top: 60px;
    padding-bottom: 60px;
`;

export const FaqArea = styled.div `
    padding-bottom: 70px;
`;

export const VideoBoxWrap = styled.div `
    position: relative;
    @media ${device.medium}{
        margin-top: 30px;
    }
    img{
        border-radius: 5px;
    }
`;

export const VideoBtnWrap = styled.div `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const NavigationArea = styled.div ``;

export const Navigtion = styled.div `
    display: flex;
    justify-content: space-between;
`;