import React from 'react';
import {Divider, Progress} from 'antd';
import {List} from '../../../containers/blog/post-list/post-list.style';
import {Col} from '../wrapper/col';
import Text from '../text';

const scoreStyles = {display: 'flex', justifyContent: 'space-between'};
const dividerStyles = {margin: "5px 0"};

const ScoreList = ({scores}) => {
  const {
    overall_score: overallScore,
    quality_of_life_score: qualityOfLifeScore,
    family_score: familyScore,
    cost,
    internet: internet,
    fun,
    safety: safety
  } = scores;

  const _calculate = (info, type) => {
    let score = 0;
    let color = "#326CFC";
    switch (info) {
      case 'Overall':
        score = Math.floor(eval(overallScore.slice(0, overallScore.indexOf(' ('))) * 100);
        break;
      case 'Good':
        score = 80;
        color = '#12B077';
        break;
      case 'Okay':
        score = 60;
        color = '#ffd918';
        break;
      case 'Bad':
        score = 40;
        color = '#e14d4d';
        break;
      case 'Way too expensive':
        score = 20;
        color = '#FD6D4D';
        break;
      case 'Too expensive':
        score = 35;
        color = '#eb4d4f';
        break;
      case 'Pricey':
        score = 50;
        color = '#FCEF65';
        break;
      case 'Affordable':
        score = 85;
        color = '#12B077';
        break;
      case 'Cheap':
        score = 100;
        color = '#12B077';
        break;
      case 'Super fast':
        score = 100;
        color = '#12B077';
        break;
      case 'Fast':
        score = 85;
        color = '#12B077';
        break;
      case 'Slow':
        score = 50;
        color = '#b9bb14';
        break;
      case 'Great':
        score = 100;
        color = '#12B077';
        break;
      case 'Perfect':
        score = 100;
        color = '#12B077';
        break;
      case 'Nice':
        score = 85;
        color = '#12B077';
        break;
      case 'Ongoing armed conflict':
        score = 20;
        color = '#FD6D4D';
        break;
      default:
        score = 75;
        color = '#ffd918';
        break;
    }
    if (type === 'score') {
      return score;
    } else return color;
  }

  return (
    <div style={{marginBottom:'20px'}}>
      <List>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Overall score</Text>
          </div>
          <div >
            <Progress style={{height:''}}  percent={_calculate('Overall', 'score')} />
          </div>
        
        <Divider style={dividerStyles}/>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Quality of life score</Text>
            <Text align="end">{qualityOfLifeScore}</Text>
          </div>
          <Progress style={{height:''}} percent={_calculate(qualityOfLifeScore, 'score')}  strokeColor={_calculate(qualityOfLifeScore)}/>
        
        <Divider style={dividerStyles}/>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Family score</Text>
            <Text align="end">{familyScore}</Text>
          </div>
          <Progress style={{height:''}} percent={_calculate(familyScore, 'score')}  strokeColor={_calculate(familyScore)}/>
        
        <Divider style={dividerStyles}/>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Cost</Text>
          </div><Progress style={{height:''}} percent={_calculate(cost.slice(0, cost.indexOf(':')), 'score')}  strokeColor={_calculate(cost.slice(0, cost.indexOf(':')))}/>
        
        <Divider style={dividerStyles}/>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Internet</Text>
            <Text align="end">{internet}</Text>
          </div>
          <Progress style={{height:''}} showInfo={false} percent={_calculate(internet.slice(0, internet.indexOf(':')), 'score')}  strokeColor={_calculate(internet.slice(0, internet.indexOf(':')))}/>
                
        <Divider style={dividerStyles}/>
        
          <div style={scoreStyles}>
            <Text style={{fontWeight:'400', fontSize:'1rem'}}>Safety</Text>
            <Text align="end">{safety}</Text>
          </div>
          <Progress style={{height:''}} percent={_calculate(safety, 'score')}  strokeColor={_calculate(safety)}/>
        
      </List>
    </div>
  )
}

export default ScoreList;