import React from "react";

function Announcment({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '300'}
      height={height ? height : '300'}
      x="0"
      y="0"
      fill="#1A1A1A"
      data-name="Layer 1"
      viewBox="0 0 100 100"
    >
      <path d="M63.12 72.19a1.34 1.34 0 01-.4-.06L34.63 63.2a1.34 1.34 0 01.81-2.55L61.78 69V35.55L35.21 48a1.34 1.34 0 11-1.14-2.42l28.48-13.35a1.35 1.35 0 011.91 1.21v37.42a1.34 1.34 0 01-1.34 1.33zM29.05 62H26a4.34 4.34 0 01-4.34-4.34v-6.35A4.35 4.35 0 0126 47h3a1.33 1.33 0 011.33 1.34v12.33A1.33 1.33 0 0129.05 62zM26 49.64a1.67 1.67 0 00-1.67 1.67v6.35A1.67 1.67 0 0026 59.33h1.67v-9.69zM67 45.27a1.34 1.34 0 01-.68-2.49l6.82-4a1.33 1.33 0 111.35 2.3l-6.81 4a1.3 1.3 0 01-.68.19zM73.79 64.94a1.26 1.26 0 01-.67-.19l-6.82-4a1.34 1.34 0 111.36-2.3l6.81 4a1.34 1.34 0 01-.68 2.49zM76 53.49h-7.82a1.34 1.34 0 010-2.68H76a1.34 1.34 0 010 2.68z"></path>
    </svg>
  );
}

export default Announcment;
