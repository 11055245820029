import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import {Tabs, Progress, Divider} from "antd";
import SEO from "../../components/seo";
import Layout from "../../containers/layout/layout";
import Header from "../../containers/layout/header/header-one";
import Footer from "../../containers/layout/footer/footer-one";
import { Container, Box, Row, Col } from "../../components/ui/wrapper";
import Heading from "../../components/ui/heading";
import Text from "../../components/ui/text";
import Icon from "../../components/ui/icon";
import Announcement from "../../components/svg/Announcement";
import Tip from "../../components/svg/Tip";
import Flight from "../../components/svg/Flight";
import Image from "../../components/image";
import VideoButton from "../../components/ui/video-button";
import Attraction from '../../containers/elements/box-large-image/section-three'
import { MdTrendingFlat } from "react-icons/md";
import FeaturesArea from '../../containers/index-services/features-area'
import ModalVideo from "../../components/ui/modal-video";
import Social, { SocialLink } from "../../components/ui/social";
import TripAdvisor from '../../assets/svg/TripAdvisor';
import TestimonialSection from "../../containers/global/testimonial-area/section-one";
import CTA from "../../containers/global/cta-area/section-one";
import PostNav from "../../components/post-nav/layout-one";
import ScoreList from '../../components/ui/list/score-list';
import axios from 'axios';
import {
  SectionWrap,
  ListGroupWrap,
} from "../../containers/elements/lists/section-one/section.style";

import {List, ListItem, ListLink} from '../../containers/blog/post-list/post-list.style'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import AccordionWrap from "../../components/ui/accordion";
import {
  BannerArea,
  BannerTextWrap,
  CityContainer,
  OverviewArea,
  ClientBox,
  MetaBox,
  ProblemArea,
  ProblemBox,
  ProblemTextBox,
  ResultArea,
  FaqArea,
  VideoBoxWrap,
  VideoBtnWrap,
  NavigationArea,
  Navigtion,
  Weather
} from "./city-template.style";


const { TabPane } = Tabs;

const CaseStudyTemplate = ({
  data,
  pageContext: { next, previous },
  location,
  ...restProps
}) => {
  const cityData = data.cityData.edges[0].node.data;
  const {
    Name,
    Region,
    Country,
    Note,
    FlightTitle,
    FlightDescription,
    TipsTitle,
    TipsDescription,
    Feature,
    Photo,
    WorldRegion,
  } = cityData;

  let bannerImg;

  if (Photo[0].url) {
    bannerImg = Photo[0].url;
  }

  const {
    taglineStyle,
    overviewStyles: { overviewHeading, clientName, metaHeading, listOneStyle },
    social,
    problemSolStyles: { problemHeading, problemIcon },
    resultStyles: { resultText },
  } = restProps;

  const [videoOpen, setVideoOpen] = useState(false);
  const [weatherKey, setWeatherKey] = useState();


  const tips = data.tips.edges;

  const posts = data.posts.posts.edges;

  const scores = data.scores.edges[0].node.data;


  console.log('this is posts', posts);

  const flightsArrival = data.flightsArrival.edges;
  const flightsDeparture = data.flightsDeparture.edges;
  const topTen = data.topTen.edges;

  let flightsArrivalHalf = Math.floor(flightsArrival.length / 2)
  let flightsArrivalFirstHalf = flightsArrival.slice(0, flightsArrivalHalf);
  let flightsArrivalSecondHalf = flightsArrival.slice(flightsArrivalHalf, flightsArrival.length);


  let flightsDepartureHalf = Math.floor(flightsDeparture.length / 2)
  let flightsDepartureFirstHalf = flightsDeparture.slice(0, flightsDepartureHalf);
  let flightsDepartureSecondHalf = flightsDeparture.slice(flightsDepartureHalf, flightsDeparture.length);

  const AllTopTen = ["Restaurants", "Attractions", "Hotels"];

  const truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };
  

  // if (pageData.video) {
  //     var { video_link } = pageData.video;
  //     var video_arr = video_link.split('=', -1);
  //     var video_id = video_arr[1];
  // }
  const modalVideoOpen = () => {
    setVideoOpen(true);
  };

  const getWeather = async () => {
    let res = await axios.get("https://cors-anywhere.herokuapp.com/http://dataservice.accuweather.com/locations/v1/cities/search?apikey=LdA8ytprqBLdsVx6pizszL01lGCXtu7n&q="+Name);
    let { data } = res;
    let res1 = await axios.get("https://cors-anywhere.herokuapp.com/http://dataservice.accuweather.com/currentconditions/v1/"+data[0]['Key']+"?apikey=LdA8ytprqBLdsVx6pizszL01lGCXtu7n");
    console.log('res1222', res1)
    setWeatherKey(res1.data[0]);
  }

  if(!weatherKey){
      getWeather()    
  }
 

  const getTopTen = (data, type) => {
    let topTen = data.filter(d => {
       return d.node.data.Type == type;
    });

    return topTen.sort(function(a, b) {
        return a.node.data.Ranking - b.node.data.Ranking;
    });
  }

  const modalVideoClose = () => {
    setVideoOpen(false);
  };
  return (
    <Layout location={location}>
      <Header />
      <SEO title={Name} />
      <main className="site-wrapper-reveal main-wrapper">
       <CityContainer>
        <Row>
          <Col lg={3}>
            <div style={{padding:'30px', paddingRight:'30px'}}>
              <span style={{height:'150px', marginBottom:'10px', borderRadius:'8px', width:'100%', background:"url("+bannerImg+") no-repeat center center", backgroundSize:"cover", display:'block'}}></span>
              <Heading as="h3">{Name}</Heading>
                {TipsTitle && (
                  <ClientBox mb="0px" style={{marginBottom:'0px'}}>
                    <Heading {...clientName} >{Country} <Text as="span">/ {WorldRegion}</Text></Heading>
                  </ClientBox>
                )}

                {weatherKey && 
                  <div>
                  <Weather>
                    <span className="weather-icon"><img src={`https://developer.accuweather.com/sites/default/files/${weatherKey.WeatherIcon.toString().length > 1 ? weatherKey.WeatherIcon : "0"+weatherKey.WeatherIcon  }-s.png`} /></span>
                    <p> <span className="degree">{weatherKey.Temperature.Imperial.Value}</span> <span className="weather-description"> {weatherKey.WeatherText} </span></p>                    
                    <span className="unit"> {weatherKey.Temperature.Imperial.Unit} </span>
                  </Weather>                  
                  </div>
                }
                
                <Tabs defaultActiveKey="1" style={{padding:'5px', marginBottom:'20px'}} >
                  <TabPane tab={<Announcement height="40px" width="50px" />} key="1">
                    {Note}
                  </TabPane>
                  <TabPane tab={<Tip height="30px" width="40px" />}  key="2">
                    {TipsDescription}
                  </TabPane>
                  <TabPane tab={<Flight height="35px" width="45px" />} key="3">
                    {FlightDescription}
                  </TabPane>
                </Tabs>

                <ScoreList scores={scores}/>


              {posts.length > 0 && ( <Row>
                <Heading as="h6" mt="20px" ml="10px" mb="20px"> Related Posts </Heading>
                <List style={{marginLeft:"10px"}}>
                    {posts && posts.map((latestBlog, i) => (
                        <ListItem key={`${latestBlog.node.slug}-${i}`}>
                            <ListLink path={`/${latestBlog.node.slug}`}>
                                <MdTrendingFlat className="icon icon-1"/>
                                <MdTrendingFlat className="icon icon-2"/>
                                <span>{latestBlog.node.title}</span>
                            </ListLink>
                        </ListItem>
                    ))}
                </List>
                </Row>

                )}                       
             </div>
          </Col>
          <Col lg={9}>
            
            <h5 style={{marginTop:'25px'}}>Top Ten</h5>
            <Row>




            {AllTopTen && AllTopTen.map(att => 
              <Col lg={4}>
                <h3>{att}</h3>
                {topTen && getTopTen(topTen, att).map(t => (
                  <p className="top-ten-item"><span>{t.node.data.Ranking}. <a className={t.node.data.Website ? 'site-exists' : ''} href={t.node.data.Website ? t.node.data.Website : '#'} target="_blank">{truncate(t.node.data.Name, 34)}</a></span><a href={t.node.data.Link} target="_blank"><TripAdvisor height="15px" width="15px" /></a></p>              
                ))}
              </Col>
            )

            }          

            </Row>

            {Note && (<Attraction data={tips}/>)}
            
          </Col>
        </Row>

      
        
        
        <NavigationArea>
          <Container>
            <Row>
              <Col md={12}>
                <Navigtion>
                  {previous && (
                    <PostNav
                      rel="prev"
                      slug={previous.fields.slug}
                      title={previous.title}
                      image={previous.image.childImageSharp.fixed}
                    />
                  )}
                  {next && (
                    <PostNav
                      rel="next"
                      slug={next.fields.slug}
                      title={next.title}
                      image={next.image.childImageSharp.fixed}
                    />
                  )}
                </Navigtion>
              </Col>
            </Row>
          </Container>
        </NavigationArea>
        <FeaturesArea />
        <CTA />
        </CityContainer>
      </main>

      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $name: String!) {
    cityData: allAirtable(
      filter: { table: { eq: "Cities" }, data: { Slug: { eq: $slug } } }
    ) {
      edges {
        node {
          data {
            Name
            Region
            Country
            Note
            FlightTitle
            FlightDescription
            TipsTitle
            TipsDescription
            WorldRegion
            Photo {
              url
            }
            Feature
          }
        }
      }
    }
    tips: allAirtable(
      filter: { table: { eq: "Tips" }, data: { TipsCities: { eq: $name } } }
    ) {
      edges {
        node {
          data {
            Title
            Description
            Photo {
              url
            }
            Link
          }
        }
      }
    }
    

    flightsArrival: allAirtable(
      filter: { table: { eq: "Flights" }, data: { FAC: { eq: $name } } }
    ) {
      edges {
        node {
          data {
            FlightTitle
            Price
            Link
            FDC
            FAC
          }
        }
      }
    }
    flightsDeparture: allAirtable(
      filter: { table: { eq: "Flights" }, data: { FDC: { eq: $name } } }
    ) {
      edges {
        node {
          data {
            FlightTitle
            Price
            Link
            FDC
            FAC
          }
        }
      }
    }
    topTen: allAirtable(
      filter: { table: { eq: "TopTen" }, data: { TopTenCity: { eq: $name } } }
    ) {
      edges {
        node {
          data {
            Name
            Type
            Ranking
            Link
            Website
            Rating

          }
        }
      }
    }
    posts: wpcontent {
      posts(where: { tagSlugIn: [$slug] }) {
        edges {
          node {
            id
            title
            date
            slug
          }
        }
      }
    }
    scores: allAirtable(
        filter: { table: { eq: "CityData" },   data: { city: { eq: $name } }}
    ) {
        edges {
            node {
                data {
                    overall_score
                    quality_of_life_score
                    family_score
                    cost
                    internet
                    fun
                    safety
                }
            }
        }
    }
  }
`;

CaseStudyTemplate.propTypes = {
  bannerStyle: PropTypes.object,
  taglineStyle: PropTypes.object,
  overviewStyles: PropTypes.object,
  social: PropTypes.object,
  problemSolStyles: PropTypes.object,
  resultStyles: PropTypes.object,
  faqStyles: PropTypes.object,
  navigation: PropTypes.object,
  ListOneStyle: PropTypes.object,
};

CaseStudyTemplate.defaultProps = {
  taglineStyle: {
    as: "h1",
    mt: "20px",
    mb: "15px",
    color: "#fff",
  },
  overviewStyles: {
    listOneStyle: {
      layout: "separator",
    },
    overviewHeading: {
      as: "h5",
      fontSize: "28px",
      lineHeight: 1.43,
      fontweight: 400,
      color: "#002fa6",
    },
    clientName: {
      as: "h6",
      fontweight: "500",
      mb: ".25rem",
    },
    metaHeading: {
      texttransform: "uppercase",
      fontweight: 500,
      fontSize: "14px",
      letterspacing: "1px",
      color: "#333",
    },
  },
  social: {
    color: "#ababab",
    fontSize: "15px",
    tooltip: true,
    tooltip_position: "bottom-left",
  },
  problemSolStyles: {
    problemHeading: {
      as: "h6",
      position: "relative",
      fontSize: "15px",
      fontweight: 500,
      lineHeight: 1.74,
      mb: "20px",
    },
    problemIcon: {
      fontSize: "18px",
      fontweight: 400,
      pr: "15px",
      mt: "5px",
    },
  },
  resultStyles: {
    resultText: {
      fontSize: "24px",
    },
  },
};

export default CaseStudyTemplate;
