import React from "react";

function Tip({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '300'}
      height={height ? height : '300'}
      x="0"
      y="0"
      fill="#7c7c7c"
      viewBox="0 0 24 24"
    >
      <path
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontFeatureSettings: "normal",
          WebkitTextIndent: "0",
          textIndent: "0",
          WebkitTextAlign: "start",
          textAlign: "start",
          WebkitTextDecorationLine: "none",
          textDecorationLine: "none",
          WebkitTextDecorationStyle: "solid",
          textDecorationStyle: "solid",
          WebkitTextDecorationColor: "#000000",
          textDecorationColor: "#000000",
          WebkitTextTransform: "none",
          textTransform: "none",
          WebkitTextOrientation: "mixed",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: "0",
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000000",
          solidOpacity: "1",
        }}
        d="M12 1031.382v.002c-3.308 0-6 2.69-6 5.998.002 1.27.83 2.501 1.584 3.71.695 1.115 1.32 2.206 1.416 3v.29h.02v1.998a2.985 2.985 0 002.98 2.98 2.985 2.985 0 002.98-2.98v-1.998H15v-.282c.095-.796.722-1.888 1.418-3.003.755-1.211 1.582-2.445 1.582-3.717a6.006 6.006 0 00-6-5.998zm0 .959v.002a5.032 5.032 0 015.04 5.039c0 .806-.684 1.994-1.438 3.205-.572.917-1.184 1.845-1.448 2.795H9.848c-.263-.951-.877-1.88-1.45-2.8-.754-1.209-1.436-2.397-1.437-3.202 0-2.789 2.25-5.04 5.039-5.04zm-2.02 12.04h4.04v2.001H9.98v-2zm.27 3h3.5a1.999 1.999 0 01-1.75 1.02c-.76 0-1.405-.41-1.75-1.02z"
        color="#000"
        enableBackground="accumulate"
        fontFamily="sans-serif"
        overflow="visible"
        transform="translate(0 -1028.362)"
      ></path>
    </svg>
  );
}

export default Tip;
