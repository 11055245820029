import React from "react";

function Flight({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '300'}
      height={height ? height : '300'}
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 80 80"
    >
      <path d="M72.818 21.312l-.042-.097c-.701-1.6-3.2-1.936-5.174-1.936-2.565 0-5.783.593-7.668 1.418l-16.039 7.755c-2.545-.494-4.542-.895-6.138-1.215-6.374-1.28-6.487-1.277-9.248-.706-.372.077-.794.165-1.293.261a1 1 0 00-.777.753c-.157.687-.157.687 2.943 2.981.971.719 2.275 1.684 4.022 2.995l-.744.36c-2.219 1.076-2.219 1.076-7.471 3.37l-4.721 2.063c-1.391-.431-8.032-2.474-9.105-2.474a.746.746 0 00-.288.05l-3.5 1.026a.954.954 0 00-.567.752.956.956 0 00.346.877l9.062 7.378c1.572 1.079 3.729 1.441 5.865.98 7.516-1.626 12.541-3.821 15.881-5.29.173 8.881-.178 13.446-.35 15.672-.13 1.692-.162 2.095.292 2.324.146.074.288.11.433.11.411 0 .744-.243 1.849-1.047.463-.336 1.07-.778 1.883-1.35.757-.533 1.154-1.661 2.473-5.398a433.132 433.132 0 015.866-15.759l13.895-6.08c1.725-.755 4.055-2.448 5.799-4.214 1.564-1.582 3.271-3.834 2.516-5.559zm-3.228 4.859c-1.638 1.658-3.895 3.303-5.488 4.001l-14.273 6.246-.076.192a443.078 443.078 0 00-5.954 15.983c-1.139 3.229-1.609 4.564-2.105 4.913-.817.575-1.429 1.021-1.895 1.359-.467.34-.842.612-1.07.754.009-.293.044-.752.083-1.256.177-2.304.546-7.096.337-16.519l-.019-.744-1.28.561c-3.303 1.453-8.295 3.648-15.78 5.269-1.863.4-3.755.086-5.054-.804l-8.972-7.307 3.345-.977c.824.032 5.911 1.533 8.96 2.483l.178.055 5.063-2.213c5.27-2.302 5.27-2.302 7.507-3.386l2.22-1.073-.656-.493a701.775 701.775 0 00-4.684-3.492c-1.495-1.106-2.316-1.714-2.542-1.954.49-.095.909-.182 1.277-.258 1.056-.219 1.693-.351 2.438-.351 1.146 0 2.687.31 6.411 1.058 1.631.328 3.681.74 6.308 1.248l.163.032 16.32-7.892c1.756-.769 4.806-1.326 7.25-1.326 2.334 0 3.886.487 4.257 1.335l.042.097c.403.918-.462 2.585-2.311 4.459z"></path>
    </svg>
  );
}

export default Flight;
